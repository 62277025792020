import {get, post} from '@/utils/http.js';

/*
function:获取身份证信息
author:ysk
time:2024/11/13
*/

export function getIdCardInfo(data: any) {
    return post('/degreeenglish/management/getUserInfo', data);
}

/*
function:提交表单
author:ysk
time:2024/11/13
*/
export function saveFromData(data: any) {
    return post('/degreeenglish/management/from', data);
}

/*
function:获取实名认证信息
author:ysk
time:2024/11/13
*/
export function getStuInfo(data: any) {
    return get('/degreeenglish/management/getpostfrom', data);
}

/*
function:提交报考信息
author:ysk
time:2024/11/13
*/
export function postExamInfo(data: any) {
    return post('/degreeenglish/management/getpostfrom', data);
}

/*
function:获取报考信息
author:ysk
time:2024/11/13
*/
export function getExamInfo(data: any) {
    return get('/degreeenglish/management/getUserInfo', data);
}

/*
function:获取报考科目
author:ysk
time:2024/11/13
*/
export function getKM(data: any) {
    return get('/degreeenglish/management/getKM', data);
}

/*
function:获取准考证信息
author:ysk
time:2024/11/13
*/
export function getTicket(data: any) {
    return get('/degreeenglish/management/getExamNumber', data);
}